@font-face {
    font-family: 'Urbanist';
    src: local('Urbanist Regular'), local('Urbanist-Regular'),
        url('./Urbanist-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Urbanist';
    src: local('Urbanist Bold'), local('Urbanist-Bold'),
        url('./Urbanist-Bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Urbanist';
    src: local('Urbanist Semibold'), local('Urbanist-Semibold'),
        url('./Urbanist-SemiBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Urbanist';
    src: local('Urbanist Extrabld'), local('Urbanist-Extrabld'),
        url('./Urbanist-ExtraBold.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
