.ScreenOne {
    height: calc(100vh - 92px);
    
    .section-img {
        img {
            width: 462px;
        }
    }

    .section-action {
        display: flex;

        div {
            position: relative;
        }

        .store-badge {
            margin-top: -16px;
        }

        .badge {
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

@media (max-width: 768px) {
    .ScreenOne {
        height: auto;
        padding: 48px 0;
    }
}

@media (max-width: 540px) {

    .ScreenOne {
        
        .section-img {
            img {
                width: 288px;
            }
        }
    }
}

