.ScreenTwo {
    .section-img {
        position: relative;
        img {
            width: 274px;
            object-fit: contain;
            border-radius: 26px;

            &:nth-of-type(2) {
                position: absolute;
                bottom: -10%;
                right: -12%;
                width: 116px;
                height: 177px;
            }
        }
    }
}

@media (max-width: 540px) {

    .ScreenTwo {
        
        .section-img {
            img {

                &:nth-of-type(2) {
                    width: 116px;
                    height: 177px;
                }
            }
        }
    }
}