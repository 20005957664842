.modal__overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
    z-index: 100;

    .modal {
        width: 492px;
        height: 404px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 48px 32px;
        background: #FFFFFF;
        border-radius: 24px;

        .modal-img {
            margin-bottom: 24px;
        }

        .modal-title {
            margin-bottom: 24px;
            font-weight: 600;
            font-size: 28px;
            line-height: 36px;
            color: #140A29;
            text-align: center;
        }

        .modal-text {
            margin-bottom: 24px;
            font-weight: normal;
            font-size: 24px;
            line-height: 32px;
            color: #50527E;
            text-align: center;
        }

        .error {
            color: #F84747;
        }
    }
}


@media (max-width: 540px) {
    .modal__overlay {

        .modal {
            width: 90%;
            height: auto;
            transform: translate(0, -50%);
            
            .modal-img {
                margin-bottom: 16px;
            }

            .modal-title {
                margin-bottom: 16px;
                font-size: 24px;
                line-height: 32px;
            }

            .modal-text {
                margin-bottom: 16px;
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}