.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Urbanist';
    font-style: normal;
    color: white;
    border: none;
    cursor: pointer;
}

.default {
    height: 28px;
    padding: 0 20px;
    background: none;
    border-radius: 26px;
    border: 1px solid #FFFFFF;

    .btn-text {
        color: white;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
    }

    &:hover,
    &:active {
        background: #FFFFFF;
        
        .btn-text {
            color: #F84747;
        }
    }
}

.primary {
    width: 288px;
    height: 56px;
    background: #F84747;
    border-radius: 30px; 

    .btn-text {
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        color: white;
    }

    &:hover,
    &:active {
        background: #CC3B3B;
        
    }
}

.btn-img {
    animation: rotating 2s linear infinite;
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}