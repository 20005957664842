.Faq {
    h2 {
        margin-bottom: 28px;
        font-weight: 600;
        font-size: 64px;
        line-height: 72px;
        text-align: center;
    }

    .item {
        display: flex;
        flex-direction: column;
        padding: 16px 16px 16px 24px;
        background: rgba(255, 255, 255, 0.24);
        border-radius: 30px;
        cursor: pointer;
        transition: all .3s ease-in-out;

        .item-title {
            display: flex;
            justify-content: space-between;
            
            .title-text {
                font-weight: bold;
                font-size: 20px;
                line-height: 28px;
            }

            .item-arrow {
                transition: all .3s;

                &.active {
                    transform: rotateX(180deg);
                }
            }

            img {
                width: 12px;
                object-fit: contain;
            }
        }

        .item-text {
            display: flex;
            flex-direction: column;
            width: 100%;

            p {
                font-weight: normal;
                font-size: 0;
                line-height: 0;
                white-space: pre-line;
                transition: all .3s;
            }

            &.active {
                display: flex;
                flex-direction: column;

                p {
                    margin-top: 16px;
                    font-weight: normal;
                    font-size: 24px;
                    line-height: 32px;
                }
            }
        }

        &:not(:first-of-type) {
            margin-top: 16px;
        }
    }
}


@media (max-width: 768px) {
    .Faq {
        h2 {
            margin-bottom: 16px;
            font-size: 32px;
            line-height: 44px;
            text-align: center;
        }

        .item { 
            .item-title {
                .title-text {
                    font-size: 16px;
                    line-height: 24px;
                }
            }

            .item-text {
    
                &.active {
    
                    p {
                        margin-top: 8px;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}