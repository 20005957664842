.Footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #140A29;

    span {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
    }

    .footer-logo {
        position: relative;
        display: flex;
        justify-content: center;
        img {
            position: absolute;
            transform: translate(0, -50%);
            border-radius: 10px;
        }
    }

    .footer-ready {
        margin-top: 62px;
    }

    .footer-appstore {
        margin-right: -36px;
        margin-top: 25px;
        cursor: pointer;

        display: flex;
        position: relative;

        a {
            position: relative;

            .store-badge {
                margin-top: -16px;
            }

            .badge {
                position: absolute;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .footer-foolow {
        margin-top: 33px;
    }

    .footer__social-icons {
        margin-top: 27px;
        margin-bottom: 34px;
        display: flex;

        a {
            cursor: pointer;
            opacity: 1;
            &:not(:last-of-type) {
                margin-right: 29px;
            }

            &:hover {
                opacity: .8;
            }
        }
    }

    .footer-terms {
        padding: 32px 20%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid rgba(255, 255, 255, 0.24);

        a {
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;

            &:hover {
                opacity: .8;
            }
        }
    }

    .footer-copyright {
        margin-bottom: 36px;
        text-align: center;
        font-size: 16px;
        line-height: 24px;
    }
}


@media (max-width: 768px) {
    .Footer {
        border-radius: 24px 24px 0px 0px;

        .footer-terms {
            a {
                font-size: 12px;
            }
        }
    
        .footer-copyright {
            margin-bottom: 28px;
            font-size: 12px;
        }
    }
}