.Contact {
    height: calc(100vh - 92px - 250px);

    .section-wrapper {
        align-items: flex-start;
    }

    .section__form-wrap {
        width: 40%;
        

        .section-form {
            padding-top: 132px;
            width: 100%;
            display: flex;
            flex-direction: column;

            

            .form-action {
                margin-top: 62px;
            }

        }
    }
}

@media (max-width: 768px) {
    .Contact {
        height: auto;

        .section-wrapper {
            align-items: center;

            .section-text {
                order: 0;
            }
        }

        .section__form-wrap {
            width: 60%;
            
    
            .section-form {
                padding-top: 16px;
                width: 100%;
                display: flex;
                flex-direction: column;
    
                
    
                .form-action {
                    display: flex;
                    justify-content: center;
                    margin-top: 0;
                }
    
            }
        }
    }
}


@media (max-width: 540px) {
    .Contact {

        .section__form-wrap {
            width: 100%;
        }
    }
}
