@import './assets/fonts/Urbanist/urbanist.scss';

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Urbanist', sans-serif;
    font-style: normal;
    color: white;
}

button,
input {
    &:focus {
        outline: none;
    }
}

a {
    text-decoration: none;
}

.container {
    max-width: 1076px;
    width: 100%;
    margin: 0 auto;
}

.section {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    
    &:not(:last-of-type) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.24);
    }
}

.section-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.section-text {
    display: flex;
    flex-direction: column;

    span.section-badge {
        margin-bottom: 28px;
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
        color: #A290DC;
    }
    
    h2 {
        font-weight: 600;
        font-size: 64px;
        line-height: 72px;
        white-space: pre-line;
    }
    
    p {
        margin-top: 48px;
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;
        white-space: pre-line;
    }
}

.section-action {
    margin-top: 65px;

    img {
        cursor: pointer;
    }
}

.legacy {
    padding: 50px 0;
    background: white;

    & * {
        color: black;
    }

    h1,
    h2,
    h3,
    p,
    ul {
        &:not(:last-of-type) {
            margin-bottom: 20px;
        }
    }

    table {
        margin-bottom: 20px;
    }

    h1 {
        margin-bottom: 40px;
        font-size: 48px;
        line-height: 72px;
        text-align: center;
        font-weight: 900;
    }

    h2 {
        margin: 30px 0;
        font-size: 36px;
        line-height: 46px;
    }

    h3 {
        margin-bottom: 30px;
        font-size: 24px;
        line-height: 32px;
        font-weight: 900;
        font-style: italic;
    }

    p,
    ul,
    table > * {
        font-size: 24px;
        line-height: 32px;
    }

    ul {
        margin-left: 50px;
    }

    a {
        text-decoration: underline;
        color: blue;
    }

    table {
        width: 100%;

        th,
        td {
            border: 1px solid black;
            text-align: center;
        }

        th {
            font-weight: bold;
        }
    }
}


@media (max-width: 1110px) {
    .container {
        padding: 0 16px;
    }
}

@media (max-width: 768px) {

    .section {
        height: auto;
        padding: 48px 0;
    }

    .section-wrapper  {
        flex-direction: column;
        

        h2 {
            margin-bottom: 16px;
            font-weight: 600;
            font-size: 32px;
            line-height: 44px;
            text-align: center;
        }
        
    }



    .section-text {
        order: 1;

        span.section-badge {
            margin-bottom: 8px;
            margin-top: 44px;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            text-align: center;
            color: white;
        }
        
        p {
            margin-top: 8px;
            font-size: 16px;
            line-height: 24px;
            white-space: normal;
            text-align: center;
            
        }
    }

    .section-action {
        margin-top: 17px;
        display: flex;
        justify-content: center;
    }

    .legacy { 
        h1,
        h2,
        h3,
        p,
        ul {
            

            &:not(:last-of-type) {
                margin-bottom: 10px;
            }
        }

        h1 {
            margin-bottom: 20px;
            font-size: 28px;
            line-height: 48px;
            text-align: center;
            font-weight: 900;
        }

        h2 {
            margin: 30px 0;
            font-size: 26px;
            line-height: 38px;
        }

        h3 {
            margin-bottom: 30px;
            font-size: 20px;
            line-height: 32px;
            font-weight: 900;
            font-style: italic;
        }

        p,
        ul,
        table > * {
            font-size: 18px;
            line-height: 28px;
        }

        ul {
            margin-left: 30px;
        }
    }
}





