
.input-group {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    &:not(:last-of-type) {
        margin-bottom: 24px;
    }
}

.input {
    width: 100%;
    height: 60px;
    padding: 4px 0px 4px 8px;
    background: rgba(255, 255, 255, 0.24);
    border-radius: 8px;
    border: none;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;
    overflow: hidden;
    z-index: 10;

    &::placeholder {
        color: rgba(255, 255, 255, 0.5);
    }

    &.error {
        border: 1px solid #F84747;
    }
}

.input-error {
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    transition: all .1s ease-in;

    img {
        width: 14px;
        height: 14px;
        margin-right: 3px;
    }

    span {
        color: #FB0000;
    }
    
    &.show {
        opacity: 1;
        transform: translate(0, 100%);
    }
}

@media (max-width: 768px) {
    
}

@media (max-width: 540px) {
    .input {
        height: 52px;
        border-radius: 8px 8px 0px 0px;
        font-size: 16px;
        line-height: 24px;
    }
}