.Header {

    .header__wrapper {
        padding-top: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .header-logo {
        cursor: pointer;
        img {
            width: 64px;
            height: 64px;
            border-radius: 10px;
        }
    }
}